import React, {createRef, useContext, useEffect} from "react";
import PageWrapper from "./../components/PageWrapper";
import Seo from "./../components/Seo";
import {Section, Title, Text, Button, MainTitle} from "./../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import { navigate } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import animation from "./../assets/animations/about-us-main.json"
import heroImage1 from "./../assets/image/svg/about-1.svg"
import heroImage2 from "./../assets/image/svg/about-2.svg"
import lottie from "lottie-web"

const AboutUs = () => {

    const data = useStaticQuery(graphql`
        query strapiAboutUsPageQuery {
            strapiAboutUsPage {
                title
                subtitle
                text_1
                text_2
                text_3
                text_4
                management_button_text
                careers_button_text
            }
            strapiPageHeadings(slug: {eq: "about-us"}) {
                h1
            }
        }`
    );

    const pageData = getProperty(data, 'strapiAboutUsPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    const animationContainer = createRef()

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    return (
        <>
            <Seo page="about-us"/>
            <PageWrapper footerDark>
                <div className="pt-5"/>
                <Section className="pt-5">
                    <Container>
                        <Row className="pb-5 text-center justify-content-center">
                            <Col md="10" sm="12">
                                <div id="animation-container" ref={animationContainer}/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center text-center">
                            <Col lg="9">
                                <MainTitle variant="hero" className="pt-3">{headingsData.h1}</MainTitle>
                                <Title variant="card" className="pt-3">{pageData.subtitle}</Title>
                            </Col>
                        </Row>
                        <Row className="pb-5 pbm-0 mt-5">
                            <Col md="12" lg="5" className="pt-2 about-us-img">
                                <img alt="" src={heroImage1}  style={{width:'100%'}}/>
                            </Col>
                            <Col md="12" lg="7">
                                <Text className="pb-3">{pageData.text_1}</Text>
                                <Text className="pb-3">{pageData.text_2}</Text>
                            </Col>
                        </Row>
                        <Row className="pb-5 mt-5 pbm-0 mobile-reverse">
                            <Col md="12" lg="7" className="pt-2 about-us-img">
                                <Text className="pb-3">{pageData.text_3}</Text>
                                <Text className="pb-3">{pageData.text_4}</Text>
                            </Col>
                            <Col md="12" lg="5">
                                <img alt="" src={heroImage2}  style={{width:'100%'}}/>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row className="text-center mt-5">
                            <Col sm="12" className="pb-3">
                                <Button
                                    onClick={() => navigate('/team')}
                                >{pageData.management_button_text}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    )

}

export default AboutUs;
